import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

// import {LoadingSpinnerComponent} from '../ui/loading-spinner/loading-spinner.component'
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  showSpinner: boolean = false;
  admin: any;

  constructor(private router: Router, private auth: AuthService, public formBuilder: FormBuilder) {

    let EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    this.loginForm = formBuilder.group({
      useremail: ["", Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEXP)]),],
      password: ["", Validators.compose([Validators.required])],
    });

  }

  ngOnInit(): void {

  }

  login() {

    this.showSpinner = true;

    console.log("this.loginForm ", this.loginForm.value.password)
    // console.log("inside ", userEmail)
    // console.log("inside ", userPwd)

    this.auth.signinUser(this.loginForm.value.useremail, this.loginForm.value.password).then(data => {
      console.log("data", data.user.uid)

      this.auth.userProfile(data.user.uid).subscribe(data => {
        console.log("data===>", data)
        this.admin = data

        if (this.admin._delegate._document.data.value.mapValue.fields.userGroup.stringValue === "admin") {
          this.showSpinner = false;

          this.router.navigateByUrl('/dashboard')

        } else {
          this.showSpinner = false;
          console.log("Not an Admin")
          // 
          // const type = ['', 'info', 'success', 'warning', 'danger'];
          // const color = Math.floor((Math.random() * 4) + 1);
          $.notify({
            icon: "notifications",
            message: "You do not have Administrator access"
          }, {
            type: 'danger',
            timer: 4000,
            placement: {
              from: 'top',
              align: 'center'
            },
            template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
              '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
              '<i class="material-icons" data-notify="icon">notifications</i> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              '</div>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              '</div>'
          });
          // 
        }
      }, error => {
        
        this.showSpinner = false;
        console.log(" ", error)
        // 
        $.notify({
          icon: "notifications",
          message: error

        }, {
          type: 'danger',
          timer: 4000,
          placement: {
            from: 'top',
            align: 'center'
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
        // 
      })


    }, error => {
      this.showSpinner = false;

      // 
      $.notify({
        icon: "notifications",
        message: error

      }, {
        type: 'danger',
        timer: 4000,
        placement: {
          from: 'top',
          align: 'center'
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
      // 

    })
  }
}
