import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.css']
})
export class RewardComponent implements OnInit {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=AAAAD71MioE:APA91bG5cB-1g44nUs-Ecvc5i5454KJ7rYTJYX8Vxj0jFq9clDzOaVlU7bp6iBJHshJ8IJcBhHGN_i2aJ3kXqE9JLjUMpBbSIT7tqD5o9_LgrhT0hyInU74WUu3qr1RgZeinTCWUUobI'
    })
  };
  FirebaseURL = 'https://fcm.googleapis.com/fcm/send';

  dateCurrent: any;
  dateExpire: any;
  showSpinner: boolean = false;
  messageSuccess: any;

  // 
  loopFcmToken: any = [];
  FCMTokenArray:any;

  constructor(
    public services: AuthService,
    private http: HttpClient,
    ) { }

  ngOnInit(): void {

    this.services.getALLFCMToken().subscribe(res =>{

      this.loopFcmToken =[]

      console.log("res",res)

      res.forEach((arrayItemFCM) =>{

       this.FCMTokenArray =arrayItemFCM

       if(this.FCMTokenArray.FCM != null){
        this.loopFcmToken.push(this.FCMTokenArray.FCM);
       }


    });




  })
  

  }


  Reward(message) {

    console.log(" this.loopFcmToke", this.loopFcmToken)

    this.showSpinner = true;

    const d = new Date();
    let year = d.getFullYear();

    var rewardCode = Math.random().toString(36).slice(-4);

    this.dateCurrent = new Date();

    this.dateExpire = new Date();
    this.dateExpire.setDate(this.dateExpire.getDate() + 1);

    var taken = false;
    var amount = 25;
    var userkey = year + "-" + rewardCode

    console.log("rewardCode",rewardCode)

  var newMessage = " Wishing you all a Happy Holiday and a joyful " + message +"."+ " Reward-Code is " + userkey +"."+ " How to redeem this code? Click the home button and from the home screen click menu icon and navigate to reward to redeem this code. " + "Enter this code "+ userkey +" .This code can be redeemed by only one person good Luck."

  console.log("newMessage",newMessage)
    // 
    this.services.addReward(taken, amount, this.dateCurrent, this.dateExpire, userkey).then(data =>{

      console.log("Inside====>",data);

      var FirebaseObject = {
        "notification": {
          "title": 'Gift Money Back',
          "body": newMessage,
          "sound": "default",
          "click_action": "FCM_PLUGIN_ACTIVITY",
          "icon": "fcm_push_icon"
        },
        "data": {
          "landing_page": "push-notification",
          "title": 'Gift Money Back',
          "body": newMessage,
        },
        "registration_ids":this.loopFcmToken,
        "priority": "high",
        "restricted_package_name": ""
      }
      this.http.post(this.FirebaseURL, FirebaseObject, this.httpOptions).subscribe(DATA => {
        this.showSpinner = false;
        // console.log("===>", DATA)
        this.messageSuccess = DATA
        // console.log(" ", error)
        // 
        $.notify({
          icon: "notifications",
          message: "Number of user's who will receive notification is " + this.messageSuccess.success

        }, {
          type: 'danger',
          timer: 4000,
          placement: {
            from: 'top',
            align: 'center'
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
        // 

      }, error => {
        this.showSpinner = false;
        console.log("===>", error)
        console.log(" ", error)
        // 
        $.notify({
          icon: "notifications",
          message: error

        }, {
          type: 'danger',
          timer: 4000,
          placement: {
            from: 'top',
            align: 'center'
          },
          template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
        // 

      });





    },error=>{

      console.log("====>",error);

    })



  }

}
