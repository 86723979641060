import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'listFilterPipe'
})

export class ListFilterPipePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(list: any[], filterText: string): any {
    return list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
}

}
