import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  message: any;
  messageDATA: any;

  constructor(public services: AuthService) { }

  ngOnInit(): void {

    this.services.getPrivacyPolicy().subscribe(DATA => {
      this.messageDATA = DATA
      this.message = this.messageDATA[0].message
    })

  }

  Policy(userEmail) {

    console.log("userEmail", userEmail)

    var test = this.services.updatePrivacyPolicy(userEmail)

  }

}
