import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { MarketingComponent } from './marketing/marketing.component';
import { RevenueComponent } from './revenue/revenue.component';
import { TopUpComponent } from './top-up/top-up.component';
import {WithdrawalComponent} from './withdrawal/withdrawal.component';

import { MatIconModule } from '@angular/material/icon';
// import {
//   AgmCoreModule
// } from '@agm/core';


// config firebase 
// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

//



const config = {
  apiKey: "AIzaSyDN80X13RbfQpmJYmW53TlTM1kWpW3u3A0",
  authDomain: "fish-2f3e3.firebaseapp.com",
  projectId: "fish-2f3e3",
  storageBucket: "fish-2f3e3.appspot.com",
  messagingSenderId: "67600419457",
  appId: "1:67600419457:web:a3fe4c2eca6f2f5c6c1b80",
  measurementId: "G-9L5JCJG2ZT"
};


// new
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';


// 
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// import {MatTableModule} from '@angular/material/table';

import {ListFilterPipePipe} from '../app/pipe/list-filter-pipe.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatTableModule } from '@angular/material/table' 
import {MatPaginatorModule} from '@angular/material/paginator';
// import { DialogComponent } from './dialog/dialog.component';
import {MatDialogModule} from '@angular/material/dialog';


// 


import {MatInputModule} from '@angular/material/input';
import { PolicyComponent } from './policy/policy.component';
import { RewardComponent } from './reward/reward.component';


@NgModule({
  imports: [
     // 3. Initialize
     AngularFireModule.initializeApp(config),
     AngularFirestoreModule, // firestore
     AngularFireAuthModule, // auth
     AngularFireStorageModule, // storage

  
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    // MatIconModule,
    RouterModule,
    AppRoutingModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDialogModule,
    MatInputModule,
    NgbModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    // })
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
    ListFilterPipePipe,
    PolicyComponent,
    RewardComponent,
    // DialogComponent,
    
     
  ],
  exports: [ListFilterPipePipe], 
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
