import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
// import { User } from  'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Timestamp } from 'rxjs-compat';
import { map, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  date: any;
  constructor(public afAuth: AngularFireAuth, public router: Router, private firestore: AngularFirestore,) {

  }

  // 
  addReward(taken: boolean, amount: number, createdDate: string, expireDate: string, userkey: string) {

    this.date = new Date();

    return this.firestore.doc<any>('RewardDashboard/' + 'dashboard').set({
      taken: taken,
      amount: amount,
      createdDate: createdDate,
      expireDate: expireDate,
      userkey: userkey
    });

  }

  //get FCM token for specific user

  getFCMToken(id: string) {

    return this.firestore.collection('/FCM/').doc(id).valueChanges()
  }
  // 

    //get FCM token for specific user
    getALLFCMToken() {

      return this.firestore.collection('/FCM/').valueChanges()
    }

  getuserwallet(id: string) {

    return this.firestore.collection('/userWallet/').doc(id).valueChanges().pipe(take(1))

  }


  refund(active: boolean, amount: number, blocked: boolean, dateCreated: Date, dateModified: Date, email: string, firstname: string, id: string, lastname: string, province: string, reward: string, topAccount: boolean,) {
    return this.firestore.collection('/userWallet/').doc(id).set({
      active: active,
      amount: amount,
      blocked: blocked,
      dateCreated: dateCreated,
      dateModified: dateModified,
      email: email,
      firstname: firstname,
      id: id,
      lastname: lastname,
      province: province,
      reward: reward,
      topAccount: topAccount

    })
  }


    // Get withdrawal Amount 
    getSupport() {
      // return this.firestore.collection('/Payout/').valueChanges();
      return this.firestore.collection('/TicketNo/').valueChanges({idField: 'UserNo' })
  
    }

  // Get withdrawal Amount 
  getwithdrawal() {

    // return this.firestore.collection('/Payout/').valueChanges();

    return this.firestore.collection('/Payout/').valueChanges({ idField: 'UserID' })

  }

  getPaidRequest() {
    return this.firestore.collection('/Payout/', (ref) => ref.where('Status', "==", { Status: 'payment' })).valueChanges({ idField: 'UserID' })
  }

      // 
      getSupportDone() {
        return this.firestore.collection('/TicketNo/', (ref) => ref.where('status', "==", "done")).valueChanges({ idField: 'UserID' })
      }

    // 
    getSupportInprogress() {
      return this.firestore.collection('/TicketNo/', (ref) => ref.where('status', "==", "Inprogress")).valueChanges({ idField: 'UserID' })
    }

  // 
  getSendWithdrawal() {
    return this.firestore.collection('/Payout/', (ref) => ref.where('Status', "==", "true")).valueChanges({ idField: 'UserID' })
  }
  // 
  getFailedPayment() {
    return this.firestore.collection('/Payout/', (ref) => ref.where('Status', "==", "false")).valueChanges({ idField: 'UserID' })
  }

  

  // 
  // User Paid
  // getwithdrawalYes(id:string,Status:string ) {

  //   return this.firestore.collection('/Payout/').doc(id).update({
  //     Status:Status
  //   })
  // }



  getRequestStatusDone(UserNo: string, status: string) {

    return this.firestore.collection('/TicketNo/').doc(UserNo).update({
      status: status
    })

  }

  getRequestStatus(UserNo: string, status: string) {

    return this.firestore.collection('/TicketNo/').doc(UserNo).update({
      status: status
    })

  }

  getwithdrawalStatus(id: string, Status: string) {

    return this.firestore.collection('/Payout/').doc(id).update({
      Status: Status
    })
  }

  // User Paid
  getwithdrawalNo(id: string, Status: string) {

    return this.firestore.collection('/Payout/').doc(id).update({
      Status: 'false'
    })
  }



  // Get Premium Amount 
  getPrivacyPolicy() {
    return this.firestore.collection('PrivacyPolicy').valueChanges();
  }

  updatePrivacyPolicy(message) {
    var UpdatedDate = new Date();
    this.firestore.collection('PrivacyPolicy').doc('St1GUn7TpXcTz3fSoEmt').update({
      updatedDate: UpdatedDate,
      message: message
    })
      .then(() => {
      }).catch(error => console.log(error));;
  }
  // basic won wager
  addPrivacyPolicy(message: string) {
    var UpdatedDate = new Date();
    return this.firestore.collection('PrivacyPolicy').add({
      updatedDate: UpdatedDate,
      message: message
    });
  }
  // 

  // update User Access
  updateUserAccess(id, data) {
    this.firestore.collection('userWallet').doc(id).update(data)
      .then(() => {
      }).catch(error => console.log(error));;
  }

  // Get Premium Amount 
  getPremiumAmountAdded() {
    return this.firestore.collection('PremiumAmountAdded').valueChanges();
  }

  // Get basic Amount 
  getStandardAmountAdded() {
    return this.firestore.collection('StandardAmountAdded').valueChanges();
  }

  // Get basic Amount 
  getPointAmountAdded() {
    return this.firestore.collection('PointAmountAdded').valueChanges();
  }

  // Get basic Amount 
  getPointAmount() {
    return this.firestore.collection('PointAmount').valueChanges();
  }

  // Get basic Amount 
  getBasicAmountAdded() {
    return this.firestore.collection('BasicAmountAdded').valueChanges();
  }

  // basic won wager
  addPointAmount(userData) {
    return this.firestore.collection('PointAmountAdded').add(userData);
  }

  // basic won wager
  addPremiumAmount(userData) {
    return this.firestore.collection('PremiumAmountAdded').add(userData);
  }

  // 
  updatePointAmount(userData) {
    this.firestore.collection('PointAmount').doc('amount').update(userData)
      .then(() => {
      }).catch(error => console.log(error));;
  }
  // 
  // 

  // 
  updatePremiumAmount(userData) {
    this.firestore.collection('PremiumAmount').doc('amount').update(userData)
      .then(() => {
      }).catch(error => console.log(error));;
  }
  // 

  // Standard won wager
  addStandardAmount(userData) {
    return this.firestore.collection('StandardAmountAdded').add(userData);
  }


  // 
  updateStandardAmount(userData) {
    this.firestore.collection('StandardAmount').doc('amount').update(userData)
      .then(() => {
      }).catch(error => console.log(error));;
  }
  // 

  // basic won wager
  addBasicAmount(userData) {
    return this.firestore.collection('BasicAmountAdded').add(userData);
  }
  // 

  updatebasicAmount(userData) {
    this.firestore.collection('BasicAmount').doc('amount').update(userData)
      .then(() => {
      }).catch(error => console.log(error));;
  }
  // 

  // Get basic Amount 
  getBasicAmount() {
    return this.firestore.collection('BasicAmount').snapshotChanges();
  }

  // Get Standard Amount
  getStandardAmount() {
    return this.firestore.collection('StandardAmount').snapshotChanges();
  }

  // Get Premium Amount
  getPremiumAmount() {
    return this.firestore.collection('PremiumAmount').snapshotChanges();
  }


  // Registered user
  RegisteredUsers() {
    return this.firestore.collection('userProfile').snapshotChanges();
  }

  // login services
  signinUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(newEmail, newPassword)
  }

  // get user admin here
  userProfile(uid) {
    return this.firestore.doc<any>('userProfile/' + uid).get();
  }

  // total basic won
  totalBasicWon() {
    return this.firestore.collection('wonWager').snapshotChanges()
  }

  // total standard won
  totalStandardWon() {
    return this.firestore.collection('wonStandardWager').snapshotChanges()
  }

  // total Premium won
  totalPremiumWon() {
    return this.firestore.collection('wonPremiumWager').snapshotChanges()
  }
  // 

  // total basic won
  totalBasiclost() {
    return this.firestore.collection('lostWager').snapshotChanges()
  }

  // total standard won
  totalStandardlost() {
    return this.firestore.collection('lostStandardWager').snapshotChanges()
  }

  // total Premium won
  totalPremiumlost() {
    return this.firestore.collection('lostPremiumWager').snapshotChanges()
  }

  // Active users
  activeUsers() {
    return this.firestore.collection('userWallet').valueChanges();
  }

  // users addPoint
  usersPoint() {
    return this.firestore.collection('addPoint').valueChanges();
  }

  getAllUsers() {
    // return this.firestore.collection('userWallet').snapshotChanges();
    return this.firestore.collection('userWallet').valueChanges();
  }


  gettopachiever() {
    return this.firestore.collection('userWallet').snapshotChanges().pipe(take(1))
    //  return this.firestore.collection('userWallet').valueChanges();
  }


  // login services
  signOut(): Promise<any> {
    return this.afAuth.signOut().then(() => {

    }).catch((error) => {
      console.log("error ", error);
    })
  }

  // signOut(): Promise<any>{
  //  // login services
  // // signinUser(newEmail: string, newPassword: string): Promise<any> {
  // //   return this.afAuth.signInWithEmailAndPassword(newEmail, newPassword)
  // // }
  // }

}
