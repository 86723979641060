import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService}  from '../../services/auth.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
1
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    { path: '/table-list', title: 'Users',  icon:'person', class: '' },
    { path: '/marketing', title: 'Marketing',  icon:'person', class: '' },
    { path: '/revenue', title: 'Revenue',  icon:'person', class: '' },
    { path: '/top-up', title: 'Top up',  icon:'person', class: '' },
    { path: '/withdrawal', title: 'withdrawal',  icon:'person', class: '' },
    { path: '/typography', title: 'Support',  icon:'headset_mic', class: '' },
    { path: '/notifications', title: 'Push Notification',  icon:'notifications', class: '' },
    { path: '/reward', title: 'Reward',  icon:'card_giftcard', class: '' },
    { path: '/policy', title: 'Privacy Policy',  icon:'book', class: '' },

// https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/

    // PolicyComponent

    // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    { path: '/upgrade', title: 'lockout',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private router : Router,private service:AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  // logout 

  logout(menuItem){
    console.log(" check what is here ",menuItem)
   
    if(menuItem.title == 'lockout'){

      this.router.navigateByUrl('/login');

    }
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  }

}
