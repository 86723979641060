// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDN80X13RbfQpmJYmW53TlTM1kWpW3u3A0",
    authDomain: "fish-2f3e3.firebaseapp.com",
    projectId: "fish-2f3e3",
    storageBucket: "fish-2f3e3.appspot.com",
    messagingSenderId: "67600419457",
    appId: "1:67600419457:web:a3fe4c2eca6f2f5c6c1b80",
    measurementId: "G-9L5JCJG2ZT"
  }

};
